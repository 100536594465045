import Vue from 'vue';

import api from '../service/api';

Vue.directive('visible', function (el, binding) {
  el.style.visibility = binding.value ? 'visible' : 'hidden';
});

Vue.directive('auth-image', function (el, binding) {
  if (binding.oldValue === undefined || binding.value !== binding.oldValue) {
    const imageUrl = binding.value;
    if (!imageUrl) return;
    el.src = 'load';
    api({
      method: 'get',
      url: imageUrl,
      responseType: 'blob',
    })
      .then(function (resp) {
        const blob = resp.data;
        const objectUrl = URL.createObjectURL(blob);
        // vnode.componentInstance.src = objectUrl;
        el.src = objectUrl;
      })
      .catch(function () {
        el.src = '';
      });
  }
});
