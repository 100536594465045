import '@mdi/font/css/materialdesignicons.css';
import Vue from 'vue';
import en from 'vuetify/es5/locale/en';
import pt from 'vuetify/es5/locale/pt';
import Vuetify from 'vuetify/lib/framework';
import '../design/app.scss';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    dark: true,
    options: { customProperties: true },
    themes: {
      dark: {
        primary: '#2D57D8',
        error: '#FF5151',
        info: '#2477b9',
        background: '#2B385A',
        backgroundSecond: '#353F60',
      },
    },
  },
  lang: {
    locales: { pt, en },
    current: 'pt',
  },
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
});
