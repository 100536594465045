import store from '../store';

export default [
  {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    meta: { authRequired: false },
    component: () => import(/* webpackChunkName: "privacyPolicy" */ './views/PrivacyPolicy.vue'),
  },
  {
    path: '/',
    name: 'Layout Main',
    component: () => import('./layouts/Main.vue'),
    redirect: { name: 'Home' },
    children: [
      {
        path: '/',
        name: 'Home',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "home" */ './views/Home.vue'),
      },
      {
        path: '/home',
        name: 'Home Redirect',
        redirect: { name: 'Home' },
      },
      {
        path: '/about',
        name: 'About',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "about" */ './views/About.vue'),
      },
      {
        path: '/live-cam',
        name: 'Live Cam',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "liveCam" */ './views/cam/LiveCam.vue'),
      },
      {
        path: '/history',
        name: 'List History',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listHistory" */ './views/history/ListHistory.vue'),
      },
      {
        path: '/menu-history',
        name: 'List Menu History',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listMenuHistory" */ './views/menu-history/ListMenuHistory.vue'),
      },
      {
        path: '/menu-lists',
        name: 'Lists Menu records',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "ListMenu" */ './views/menu-list/ListMenu.vue'),
      },
      {
        path: '/menu-item',
        name: 'List Menu Item',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listMenuItem" */ './views/menu-item/ListMenuItem.vue'),
      },
      {
        path: '/menu',
        name: 'List Menu',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listMenu" */ './views/menu/ListMenu.vue'),
      },
      {
        path: '/menu-schedule',
        name: 'Menu Schedule',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "menuSchedule" */ './views/menu-schedule/menuSchedule.vue'),
      },
      {
        path: '/customers',
        name: 'List Customers',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listCustomers" */ './views/customers/ListCustomers.vue'),
      },
      {
        path: '/devices',
        name: 'List Devices',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listDevices" */ './views/device/ListDevices.vue'),
      },
      {
        path: '/disciplines',
        name: 'List Disciplines',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listDisciplines" */ './views/disciplines/ListDisciplines.vue'),
      },
      {
        path: '/users',
        name: 'List Users',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listUsers" */ './views/users/ListUsers.vue'),
      },
      {
        path: '/plan',
        name: 'Plan Detail',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "planDetail" */ './views/plan/PlanDetail.vue'),
      },
      {
        path: '/change-plan',
        name: 'Change Plan',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "changePlan" */ './views/plan/ChangePlan.vue'),
      },
      {
        path: '/students',
        name: 'Students',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listStudents" */ './views/student/ListStudents.vue'),
      },
      {
        path: '/teachers',
        name: 'Teachers',
        meta: { authRequired: true },
        component: () => import(/* webpackChunkName: "listTeachers" */ './views/teacher/ListTeachers.vue'),
      },
      {
        path: '/student-classes',
        name: 'StudentClasses',
        meta: { authRequired: true },
        component: () =>
          import(/* webpackChunkName: "listStudentClass" */ './views/student-class/ListStudentClass.vue'),
      },
      {
        path: '/profile',
        name: 'Layout Profile',
        component: () => import('./layouts/Profile.vue'),
        redirect: { name: 'Profile Detail' },
        children: [
          {
            path: 'detail',
            name: 'Profile Detail',
            meta: { authRequired: true },
            component: () => import(/* webpackChunkName: "profileDetail" */ './views/profile/ProfileDetail.vue'),
          },
          {
            path: 'change-password',
            name: 'Profile Change Password',
            meta: { authRequired: true },
            component: () =>
              import(/* webpackChunkName: "profileChangePassword" */ './views/profile/ProfileChangePassword.vue'),
          },
        ],
      },
    ],
  },
  {
    path: '/',
    name: 'Layout Auth',
    component: () => import('./layouts/Auth.vue'),
    redirect: { name: 'Login' },
    children: [
      {
        path: '/login',
        name: 'Login',
        component: () => import('./views/auth/Login.vue'),
      },
      {
        path: '/logout',
        name: 'Logout',
        component: {
          beforeRouteEnter(to, from, next) {
            store.dispatch('auth/logout');
            next('/login');
          },
        },
      },
      {
        path: '/forgot-password',
        name: 'Forgot Password',
        component: () => import('./views/auth/ForgotPassword.vue'),
      },
      {
        path: '/register',
        name: 'Register',
        component: () => import('./views/auth/Register.vue'),
      },
      {
        path: '/register-invited/:hash',
        name: 'Register Invited',
        props: true,
        component: () => import('./views/auth/RegisterInvited.vue'),
      },
      {
        path: '/confirm-email/:hash',
        name: 'Confirm Email',
        component: {
          beforeRouteEnter(to) {
            const hash = to.params.hash;
            store.dispatch('auth/confirmEmail', hash);
          },
        },
      },
      {
        path: '/confirm-email-send/:email',
        name: 'Confirm Email Send',
        props: true,
        component: () => import('./views/auth/ConfirmEmailSend.vue'),
      },
      {
        path: '/reset-password/:hash',
        name: 'Reset Password',
        props: true,
        component: () => import('./views/auth/ResetPassword.vue'),
      },
    ],
  },
];
