import axios from 'axios';
import toastr from 'toastr';

import store from '../store';

const api = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  timeout: 30000,
});

api.interceptors.request.use(function (config) {
  const token = localStorage.getItem('token');
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
});

api.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error?.response?.status !== 401) {
      if (error?.response?.status === 422 && error?.response?.data?.errors) {
        let errors = '';
        // eslint-disable-next-line no-unused-vars
        Object.entries(error?.response?.data?.errors).forEach(([key, value]) => {
          errors += value + '<br/>';
        });
        toastr.error(errors, 'Negado');
      } else {
        toastr.error(error?.response?.data?.message || 'Ocorreu um erro interno.');
      }
    } else {
      store.dispatch('auth/logout');
    }
    return Promise.reject(error);
  },
);

export default api;
