import toastr from 'toastr';

import authService from '../../service/auth.service';

export const state = {
  user: null,
};

export const actions = {
  async login({ commit }, form) {
    const user = await authService.login(form);
    commit('SET_USER', user);
  },

  logout({ commit }) {
    authService.logout();
    commit('SET_USER', null);
    window.location = '/login';
    // window.location.reload();
  },

  async register(context, form) {
    await authService.register(form);
    toastr.success('Cadastro realizado com sucesso! Efetue o login para entrar.', null, {
      onHidden: () => (window.location = '/login'),
    });
  },

  async registerInvited(context, form) {
    await authService.registerInvited(form);
    toastr.success('Cadastro realizado com sucesso! Efetue o login para entrar.', null, {
      onHidden: () => (window.location = '/login'),
    });
  },

  async confirmEmail({ commit }, hash) {
    const user = await authService.confirmEmail(hash);
    commit('SET_USER', user);
    toastr.success('Email confirmado com sucesso!', null, { onHidden: () => (window.location = '/home') });
  },

  async resetPassword({ commit }, { hash, password }) {
    const user = await authService.resetPassword({ hash, password });
    commit('SET_USER', user);
    toastr.success('Senha alterada com sucesso!', null, { onHidden: () => (window.location = '/home') });
  },

  async validate({ commit, state }) {
    if (!authService.isLogged()) return false;
    if (!state.user) {
      const user = await authService.me();
      commit('SET_USER', user);
      return true;
    }
    return true;
  },

  async loadMe({ commit }) {
    const user = await authService.me();
    commit('SET_USER', user);
  },
};

export const mutations = {
  SET_USER(state, user) {
    state.user = user;
  },
};
