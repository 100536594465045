import Vue from 'vue';
import VueSweetalert2 from 'vue-sweetalert2';

// If you don't need the styles, do not connect
import 'sweetalert2/dist/sweetalert2.min.css';

const options = {
  background: '#353F60',
  confirmButtonColor: '#2D57D8',
  cancelButtonColor: '#FF5151',
  cancelButtonText: 'CANCELAR',
  denyButtonText: 'NÃO',
  confirmButtonText: 'OK',
};

Vue.use(VueSweetalert2, options);
