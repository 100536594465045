import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
// import dayjs from 'dayjs';
import Vue from 'vue';

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Quinta', 'Sábado'],
  dayOfWeekNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
  monthNames: [
    'Janeiro',
    'Fevereiro',
    'Março',
    'Abril',
    'Maio',
    'Junho',
    'Julho',
    'Agosto',
    'Setembro',
    'Outubro',
    'Novembro',
    'Dezembro',
  ],
  monthNamesShort: ['Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun', 'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'],
  // Timezone offset, in minutes (0 - UTC, 180 - Russia, undefined - current)
  // timezone: 0,
});

Vue.filter('cpf', function (value) {
  if (value) return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
  return value;
});

Vue.filter('phone', function (value) {
  if (value) return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  return value;
});

Vue.filter('empty', function (value) {
  if (!value) return '---';
  return value;
});
