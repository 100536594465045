import Vue from 'vue';
import VueRouter from 'vue-router';

import store from '../store';
import routes from './routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);

  if (!authRequired) return next();

  try {
    return store.dispatch('auth/validate').then((validUser) => {
      validUser ? next() : next({ name: 'Login' });
    });
  } catch (e) {
    next({ name: 'Login' });
  }
});

export default router;
