import api from './api';

class AuthService {
  login({ email, password }) {
    return api.post('/auth/login', { email, password }).then(this._callbackLogin);
  }

  logout() {
    localStorage.removeItem('token');
  }

  isLogged() {
    const token = localStorage.getItem('token');
    if (token) return true;
    else return false;
  }

  register(form) {
    return api.post('/auth/register', form).then((res) => res.data);
  }

  registerInvited(form) {
    return api.post('/auth/register-invited', form).then((res) => res.data);
  }

  sendInvite(form) {
    return api.post('/auth/send-invite', form).then((res) => res.data);
  }

  reSendInvite({ email }) {
    return api.post('/auth/re-send-invite', { email }).then((res) => res.data);
  }

  forgotPassword({ email }) {
    return api.post('/auth/forgot/password', { email }).then((res) => res.data);
  }

  resetPassword({ hash, password }) {
    return api.post('/auth/reset/password', { hash, password }).then(this._callbackLogin);
  }

  confirmEmail(hash) {
    return api.post('/auth/confirm-email', { hash }).then(this._callbackLogin);
  }

  confirmEmailSend({ email }) {
    return api.post('/auth/send-confirm-email/' + email).then((res) => res.data);
  }

  me() {
    return api.get('/auth/me').then((res) => res.data);
  }

  updateMe(payload) {
    return api.patch('/auth/me', payload).then((res) => res.data);
  }

  changePasswordMe(payload) {
    return api.patch('/auth/me/change-password', payload).then((res) => res.data);
  }

  registerStudentResponsible(payload) {
    return api.post('/auth/register-student-responsible', payload).then((res) => res.data);
  }

  _callbackLogin(res) {
    const { token, user } = res.data;
    localStorage.setItem('token', token);
    return user;
  }
}

export default new AuthService();
